body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body, html {
  margin:0 !important;
  min-height: 100vh;
  min-width: 500px;
  background: rgb(87, 105, 127);
}

@media only screen and (max-height: 800px) {
  .welcome_login {display: none; padding: 0rem;}
  .form_login {margin-top: 0rem;}
  .home_login {padding-top: 0rem;}
}
@media only screen and (min-height: 800px) {
  .welcome_login {display: block; padding: 2rem;}
  .form_login {margin-top: 2rem;}
  .home_login {padding-top: 5rem;}
}